

/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
.loader {
    width: calc(80px / cos(45deg));
    height: 14px;
    background: repeating-linear-gradient(-45deg,yellow 0 15px,#000 0 20px) left/200% 100%;
    animation: l3 2s infinite linear;
  }
  @keyframes l3 {
      100% {background-position:right}
  }